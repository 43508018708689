import "./About.css";
import { UnderConstruction } from "../../components/UnderConstruction/UnderConstruction";

export function About() {
  return (
    <>
      <UnderConstruction />
    </>
  );
}
