import "./Contact.css";
import { UnderConstruction } from "../../components/UnderConstruction/UnderConstruction";

export function Contact() {
  return (
    <>
      <UnderConstruction />
    </>
  );
}
